<template>
  <div>
    <div class="cover-image">
      <div :class="isMobile() ? 'content-mobile m-2' : 'content'">
        <div class="image mt-5">
          <b-avatar
            :src="require(`../assets/images/${avatar}`)"
            :size="isMobile() ? '15rem' : '20rem'"
          ></b-avatar>
        </div>
        <div
          class="m-4"
          style="text-align: left;"
        >
          <div class="title mt-2">{{ title[language] }}</div>
          <br />
          <div class="description">{{ content1[language] }}</div>
          <br />
          <div class="description"> {{ content2[language] }} </div>
          <br />
          <div class="description"> {{ content3[language] }} </div>
          <div class="d-flex flex-column align-items-center mt-4">
            <div class="description m-2"> {{ contactMe[language] }} </div>
            <div class="h3 m-3">
              <a
                href="https://instagram.com/navega.amorim"
                class="m-1"
                style="color:black"
              ><b-icon
                shift-h="-4"
                shift-v="4"
                icon="instagram"
              ></b-icon></a>
              <a
                href="https://twitter.com/navegaamorim"
                class="m-1"
                style="color:black"
              ><b-icon
                shift-h="0"
                shift-v="4"
                icon="twitter"
              ></b-icon></a>
              <a
                class="m-1"
                style="color:black; cursor: pointer;"
                @click="$bvModal.show('contact-form')"
              ><b-icon
                shift-h="+4"
                shift-v="4"
                icon="envelope"
              ></b-icon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: "About",
  data() {
    const config = require(`../assets/about-config/config-about.json`)
    const { cover, avatar, title, contactMe, content1, content2, content3 } = config
    return {
      language: this.$root.getLanguage(),
      cover,
      avatar,
      title,
      contactMe,
      content1,
      content2,
      content3
    };
  },
  mounted() {
    window.addEventListener('language-localstorage-changed', (event) => {
      this.language = event.detail.language;
    });
  }
};
</script>

<style scoped>
.cover-image {
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;

}

.info-horizontal-container {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2vw;
  margin: 2rem auto;
  padding: 10px;
}

.content-mobile {
  display: grid;
  grid-template-rows: 1fr 4fr;
  margin: auto;
}

.image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.title {
  color: black;
  font-family: "Raleway-Regular";
  font-size: 20pt;
}

.description {
  color: black;
  font-family: "Raleway-Light";
  font-size: 14pt;
}

</style>