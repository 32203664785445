<template>
  <div
    v-if="!$route.path.includes('home')"
    :class="showOpacity ? 'generalContainer-opacity' : 'generalContainer'"
  >
    <div class="colorWebsite p-4">
      <div class="top-container mt-3 mb-3">
        <div class="title"> © 2024 NAVEGA AMORIM  </div>
      </div>
      <!-- <div class="bottom-container mt-3">
                <div class="h3 m-2">
                    <a href="https://instagram.com/navega.amorim" style="color:black"><b-icon shift-h="-4" shift-v="4"
                            icon="instagram"></b-icon></a>
                    <a href="https://twitter.com/navegaamorim" style="color:black"><b-icon shift-h="0" shift-v="4"
                            icon="twitter"></b-icon></a>
                    <a v-on:click="$bvModal.show('contact-form')" style="color:black; cursor: pointer;"><b-icon
                            shift-h="+4" shift-v="4" icon="envelope"></b-icon></a>
                </div>
            </div> -->
      <b-modal
        id="contact-form"
        hide-footer="true"
        hide-header="true"
      >
        <ContactMe />
      </b-modal>
    </div>
  </div>
</template>

<script>

import ContactMe from '../navigation/ContactMe.vue'
export default {
    name: 'BottomBar',
    components: {
        ContactMe
    },
    props: {
        showOpacity: { type: Boolean, required: false, default: false }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    }
}
</script>

<style scoped>
.generalContainer {
    text-align: center;
    background-color: white;
    max-width: 100%;
}

.generalContainer-opacity {
    text-align: center;
    background-color: black;
    max-width: 100%;
}

.top-container {
    width: 100%;
    display: flex;
}

.bottom-container {
    height: 4vh;
    margin: auto;
}

.title {
    color: black;
    margin: auto;
    font-family: "Raleway-Light";
    font-size: 12pt;
}

.title-mobile {
    color: black;
    margin: auto;
    font-family: "Raleway";
    font-size: 12pt;
}
</style>