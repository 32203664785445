<template>
  <div class="container-fluid p-0">

    <div
      v-if="!isMobile()"
      data-aos="fade-up"
      class="page-gallery"
    >
      <div
        v-for="item in config"
        :key="item.layout"
      >
        <router-link
          v-slot="{ navigate }"
          :to="`${item.redirectTo}`"
          custom
        >
          
          <div class="item-gallery">
            
            <div
              class="item-image m-5"
              @click="navigate"
            >
              <b-img
                class="top-container-cover"
                :src="require(`../../assets/images/${item.path}/cover-desktop.jpg`)"
                fluid-grow
              />
                
              <div class="container-item-gallery">
                <div class="container-info">
                  <div class="title">{{ item.title[language] }}</div>           
                </div>
              </div>
            </div>

            <div class="item-text">
              <div class="subtitle">{{ item.subtitle[language] }}</div>
              <div class="mt-2 description">{{ item.description[language] }}</div>
            </div>
          </div>

          <hr
            style="width:50%; margin: 0 auto;"
            class="mt-2 mb-2"
          >

        </router-link>
      </div>
    </div>

    <div
      v-else
      data-aos="fade-up"
      class="aos-animate"    
    >
      <div
        v-for="item in config"
        :key="item.layout"
      >
        <router-link
          v-slot="{ navigate }"
          :to="`${item.redirectTo}`"
          custom
        >
          <div class="d-flex flex-column align-items-center">
            <div
              class="cover-image-mobile m-4"
              @click="navigate"
            >
              <b-img
                class="top-container-cover"
                :src="require(`../../assets/images/${item.path}/cover-mobile.jpg`)"
                fluid-grow
              />
              <div class="container">
                <div class="container-info-mobile">
                  <div class="title-mobile mb-2">{{ item.title[language] }}</div>
                  <div class="subtitle-mobile">{{ item.subtitle[language] }}</div>
                </div>
              </div>
            </div>

            <hr
              style="width:50%; margin: 0 auto;"
              class="mt-2 mb-2"
            >

          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CollectionsList",
  data() {
    const config = require(`../../assets/collections-config/collections-list.json`)
    return {
      config,
      language: this.$root.getLanguage()
    };
  },
  watch: {
    $route() {
      const config = require(`../../assets/collections-config/collections-list.json`)
      this.config = config
    }
  },
  mounted() {
    window.addEventListener('language-localstorage-changed', (event) => {
      this.language = event.detail.language;
    });
  }
};
</script>

<style scoped>
.container-item-gallery {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.7));
}
.page-gallery {
    margin-left: 15%;
    margin-right: 15%;
    height: 100%;
}

.item-gallery{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-image {
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
  margin: auto;
  flex: 6;
}
.item-image:hover {
  cursor: pointer;
}

.item-text {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.btn-img-group {
  position: relative;
  width: 50%;
  height: 100%;
  margin: auto
}

.btn-img-group-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto
}

.btn-img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.image {
  max-width: 100%;
  max-height: 100%;

}

.image-mobile {
  max-width: 100%;
  max-height: 100%;
}


.cover-image-mobile {
  width: 90%;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
  margin: auto
}

.cover-image:hover {
  cursor: pointer;
}

.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9));
}

.container-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5vh;
  height: 100%;
}

.container-info-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3vh;
  height: 100%;
}

.title {
  color: white;
  font-family: "Raleway-ExtraLight";
  font-size: 28pt;
}

.subtitle {
  color: black;
  font-family: "Raleway-ExtraLight";
  font-size: 22pt;
  text-align: left;
}

.description {
  color: black;
  font-family: "Raleway-Regular";
  font-size: 12pt;
  font-weight: 100;
  text-align: left;
}

.title-mobile {
  color: white;
  font-family: "Raleway-Light";
  font-size: 20pt;
}

.subtitle-mobile {
  color: white;
  font-family: "Raleway-Light";
  font-size: 13pt;
  text-align: left;
}
</style>
