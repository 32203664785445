<template>
  <div>
    <div class="d-flex justify-content-between align-items-center m-3">
      <div class="custom-text-22">Contact</div>
      <b-icon icon="x-square" scale="1.5" @click="$bvModal.hide('contact-form')"></b-icon>
    </div>
    <div class="d-flex m-3">
      <div class="custom-text-12">Feel free to send a message</div>
    </div>
    <b-form @submit="onSubmit" :state="validation">
      <b-form-group id="input-group-1" label="Your Name:" label-for="name" class="m-3 custom-text-16">
        <b-form-input id="name" v-model="form.name" placeholder="Enter name">
        </b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label="Email address:" label-for="email" class="m-3 custom-text-16">
        <b-form-input id="email" v-model="form.email" type="email" placeholder="Enter email">
        </b-form-input>
      </b-form-group>
      <b-form-group id="input-group-3" label="Your message:" label-for="message" class="m-3 custom-text-16">
        <b-form-textarea id="message" v-model="form.message" rows="5" max-rows="8">
        </b-form-textarea>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button squared type="submit" variant="info" class="m-3 button">Send</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  data() {
    return {
      form: { name: '', email: '', message: '' }
    }
  },
  methods: {
    onSubmit(event) {
      //event.preventDefault()
      //alert(JSON.stringify(this.form))
    }
  },
  computed: {
    validation() {
      return true
    }
  }
}
</script>

<style scoped>
.button {
  background-color: darkgrey;
  border: none;
  height: 50%;
}

.button:hover {
  background-color: lightgray;
}

.text-form {
  font-family: "Raleway";
  font-size: 16pt;
}
</style>