<template>
  <div id="PathHeader">
    <div 
      class="mt-4 custom-text-16"
    > 
      / 
      <router-link
        :to="{ name: 'collections'}"
        style="text-decoration: underline; text-underline-offset: 0.3em; text-decoration-thickness: 0.04rem; 
        color: inherit;"
      > 
        {{ tr['path-header']['collections'][language] }} 
      </router-link>  
      / 
      {{ collection.toLowerCase() }}
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'PathHeader',
    props: {
        collection: { type: String, required: true },
    },
    data() {
        const tr = require(`../../assets/tr.json`)
        return {
            tr,
            language: this.$root.getLanguage()
        }
    },
    mounted() {
        window.addEventListener('language-localstorage-changed', (event) => {
            this.language = event.detail.language;
        });
    },
  }
  </script>
  