<template>
  <b-container
    fluid
    class="full-container"
    @keyup.enter="nextPhoto"
  >
    <div
      v-if="!isMobile()"
      class="content"
    >
      <div class="left-content">
        <b-icon
          scale="2"
          style="color: rgba(0, 0, 0, 0.6); cursor: pointer;"
          icon="chevron-left"
          @keyup.left="previousPhoto"
          @click="previousPhoto"
        ></b-icon>
      </div>

      <div class="center-content">
        <div style="height: 10px; width: 100%;"></div>
        <b-img
          id="image"
          :class="getClass(orientation)"
          :src="photo"
        />
        <div
          class="d-flex justify-content-end"
          style="width: 100%; height: 5%; padding-right: 2%;"
        >
          <div class="center-content-text mb-4">
            {{ description + (" - " + (indexSelected + 1) + "/" + files.length)}}
          </div>
        </div>
      </div>

      <div class="right-content">
        <div
          class="right-sub-content"
          @click="$emit('callback')"
        >
          <b-icon
            scale="3"
            shift-h="-40"
            shift-v="-40"
            style="color: rgba(0, 0, 0, 0.6); cursor: pointer;"
            icon="x"
          ></b-icon>
        </div>
        <b-icon
          scale="2"
          style="color: rgba(0, 0, 0, 0.6); cursor: pointer;"
          icon="chevron-right"
          @click="nextPhoto"
          @keyup.enter="nextPhoto"
        ></b-icon>
        <div></div>
      </div>
    </div>
    <div v-else>
      <div
        class="mobile-content"
        @click="$emit('callback')"
        @touchstart="touchStart"
      >
        <div class="mobile-top-content">
          <b-icon
            scale="2"
            shift-h="-30"
            shift-v="-30"
            style="color: rgba(0, 0, 0, 0.6); cursor: pointer;"
            icon="x"
          ></b-icon>
        </div>
        <div>
          <b-img
            class="mobile-center-content-image"
            fluid-grow
            :src="photo"
          />
        </div>
        <div>
          <div
            v-if="showMobileTextTip"
            class="center-content-text mb-2"
          >{{ tr['tip']['swipe'][language] }}</div>
          <div
            v-else
            class="center-content-text-transparent mb-2"
          >{{ tr['tip']['swipe'][language] }}</div>
          <div class="center-content-text m-2">{{ description + (" - " + (indexSelected + 1) +
            "/" + files.length)
          }}</div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>

export default {
  name: "PhotoViewer",
  props: {
    collection: { type: String, required: true },
    filename: { type: String, required: true },
    files: { type: Array, required: true }
  },
  emits: ['callback'],
  data() {
    const isNumeric = (num) => (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);
    let propFileName = this.filename;
    if(isNumeric(this.filename)){
        propFileName = `image-${this.filename}.jpg`;
    }

    let indexSelected = this.files.findIndex(i => i.filename == propFileName)    
    indexSelected = indexSelected == -1 ? 0 : indexSelected
    
    const description = this.files[indexSelected].description
    const orientation = this.files[indexSelected].orientation
    const tr = require(`../../assets/tr.json`)

    //const params = { ...this.$route.params, 'filename': indexSelected + 1};
    //this.$router.push({ params });

    return {
      indexSelected,
      description,
      orientation,
      photo: require(`../../assets/images/${this.collection}/${this.files[indexSelected].filename}`),
      showMobileTextTip: true,
      tr,
      language: this.$root.getLanguage()
    };
  },
  created() {
    window.addEventListener('keydown', this.keyDownHandler)
  },
  unmounted() {
    window.removeEventListener('keydown', this.keyDownHandler)
  },
  methods: {
    keyDownHandler(e) {
      if (e.key == "ArrowRight") {
        this.nextPhoto()
      }
      if (e.key == "ArrowLeft") {
        this.previousPhoto()
      }
      if (e.key == "Escape") {

        //const params = { ...this.$route.params }
        //delete params.filename
        //this.$router.replace({ params });

        this.$emit('callback')
      }
    },
    nextPhoto() {
      if (this.indexSelected < this.files.length - 1) {
        this.indexSelected += 1

        this.photo = require(`../../assets/images/${this.collection}/${this.files[this.indexSelected].filename}`);
        this.orientation = this.files[this.indexSelected].orientation
        this.description = this.files[this.indexSelected].description

        //const params = { ...this.$route.params, 'filename': this.indexSelected + 1};
        //this.$router.replace({ params });

        this.getClass(this.orientation)
        this.$mount();
      }
    },
    previousPhoto() {
      if (this.indexSelected > 0) {
        this.indexSelected -= 1
        this.photo = require(`../../assets/images/${this.collection}/${this.files[this.indexSelected].filename}`);
        this.orientation = this.files[this.indexSelected].orientation
        this.description = this.files[this.indexSelected].description

        //const params = { ...this.$route.params, 'filename': this.indexSelected + 1};
        //this.$router.replace({ params });

        this.getClass(this.orientation)
        this.$mount();
      }
    },
    touchStart(touchEvent) {
      if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), { once: true });

    },
    touchEnd(touchEvent, posXStart) {
      this.touches = touchEvent.touches.length
      if (touchEvent.touches.length == 0) { // We only care if one finger is used
        const posXEnd = touchEvent.changedTouches[0].clientX;
        if (posXStart < posXEnd) {
          this.previousPhoto(); // swipe right
        } else if (posXStart > posXEnd) {
          this.nextPhoto(); // swipe left
        }
        this.showMobileTextTip = false
      }

    },
    getClass(orientation) {
      let classComputed = ''
      if (orientation == 'portait') {
        classComputed = 'center-content-image-portait-transition'
      }
      if (orientation == 'landscape') {
        classComputed = 'center-content-image-landscape-transition'
      }
      if (orientation == 'square') {
        classComputed = 'center-content-image-square-transition'
      }

      if (document.getElementById("image")) {
        document.getElementById("image").classList.remove(...document.getElementById("image").classList);
        document.getElementById("image").offsetWidth;
        document.getElementById("image").classList.add(classComputed);
      }

      return classComputed
    }
  }
};
</script>

<style scoped>
.full-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}

.mobile-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 100%;
}

.mobile-top-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.mobile-center-content-image {
  border: 1vh solid white;
}

.content {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  height: 100%;
}

.left-content {
  display: grid;
  align-content: center;
  justify-content: right;
  height: 100%;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.center-content-image-landscape {
  max-width: 70%;
}

.center-content-image-landscape-transition {
  max-width: 60%;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.center-content-image-portait {
  max-width: 35%;
}

.center-content-image-portait-transition {
  max-width: 35%;
  animation: fadeIn ease 1s;
}

.center-content-image-square {
  max-width: 50%;
}

.center-content-image-square-transition {
  max-width: 50%;
  animation: fadeIn ease 1s;
}

.center-content-text {
  color: black;
  font-family: "Raleway-ExtraLight";
  font-size: 11pt;
  font-style: italic;
  text-align: center;
}

.center-content-text-count {
  color: white;
  font-family: "Raleway";
  font-size: 10pt;
  font-style: italic;
}

.center-content-text-transparent {
  color: transparent;
  font-family: "Raleway";
  font-size: 12pt;
  font-style: italic;
}

.right-content {
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
}

.right-sub-content {
  display: grid;
  justify-content: right;
  height: 100%;
}
</style>