import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import NotFound from '@/components/navigation/NotFound.vue'
import CollectionsList from '@/components/gallery/CollectionsList.vue';
import CollectionViewer from '@/components/gallery/CollectionViewer.vue';
import About from '@/views/About.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { auth: false }
  }, 
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { auth: false }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { auth: false }
  },
  {
    path: '/collections',
    name: 'collections',
    component: CollectionsList
  },
  {
    path: '/collection/:collection',
    name: 'grid',
    component: CollectionViewer,
    meta: { auth: false }
  },   
  {
    path: '/collection/:collection/:filename',
    name: 'photo',
    component: CollectionViewer
  },    
  {
    path: '/about',
    name: 'about',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
