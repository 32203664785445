<template>
  <div>
    <div :class="!isMobile() ? 'page' : 'm-4'">
      <PathHeader :collection="title[language]" />

      <div class="title mt-5">{{ title[language] }}</div>

      <div
        class="subtitle mt-1 mb-5"
        style="text-align:left"
      >
        {{ subtitle[language] }}
      </div>

      <div
        class="description mt-5 mb-5"
        style="text-align:left"
      >
        {{ description[language] }}
      </div>
    </div>

    <div
      v-for="(item, index) in layout"
      :key="item.layout"
    >

      <div :class="getRowClass(index)">
        <div :class="!isMobile() ? 'page' : 'm-4'">

          <div :class="getClass(item)">
            <div
              v-for="photo in item.components"
              :key="photo.filename"
            >

              <div
                v-if="photo.type == 'picture'"
                data-aos="fade-up"
              >
                <div
                  class="img-container"
                  @click="openImage(photo.filename, photo.orientation)"
                >
                  <img
                    class="type-img"
                    :src="getPhoto(photo.filename)"
                  >
                </div>
              </div>

              <div
                v-else-if="photo.type == 'text'"
                class="type-text-container"
              >
                <div
                  class="type-text"
                  :style="isMobile() ? 'text-align: left' : 'text-align:' + photo.align"
                >{{
                  photo.text[language] }}</div>
              </div>

              <div
                v-else-if="photo.type == 'citation'"
                class="type-text-container"
              >
                <div>
                  <div class="type-citation">{{ photo.text }}</div>
                  <hr
                    style="width:25%; margin: 0 auto;"
                    class="mt-2 mb-2"
                  >
                  <div class="type-citation-author mt-1">{{ photo.author }}</div>
                </div>
              </div>

              <div
                v-else-if="photo.type == 'poem'"
                class="type-text-container"
              >
                <div>
                  <div class="type-poem">{{ photo.text }}</div>
                  <hr
                    style="width:25%; margin: 0 auto;"
                    class="mt-2 mb-2"
                  >
                  <div class="type-citation-author mt-1">{{ photo.author }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showPhotoViewer">
      <PhotoViewer
        :collection="collection"
        :filename="filename"
        :files="files"
        @callback="closeImage"
      />
    </div>
  </div>

</template>

<script>
import PhotoViewer from '../gallery/PhotoViewer.vue'
import PathHeader from '../navigation/PathHeader.vue';

export default {
    name: "Collection",
    components: {
        PhotoViewer,
        PathHeader
    },
    data() {
        const { collection, filename } = this.$route.params;
        const showPhotoViewer = filename ? true : false;

        const config = require(`../../assets/collections-config/${collection}`)
        const { layout, title, subtitle, description } = config

        let files = []
        config.layout.forEach(element => {
            element.components.forEach(file => {
                if (file.type == "picture") {
                    files.push({
                        filename: file.filename,
                        orientation: file.orientation,
                        description: file.description
                    })
                }
            })
        });

        return {
            layout,
            collection,
            title,
            subtitle,
            description,
            showPhotoViewer,
            filename,
            files,
            language: this.$root.getLanguage()
        };
    },
    computed: {
        photo() {
            return this.photos.find((photo) => {
                return photo.photoid === Number(this.$route.params.collection);
            });
        }
    },
    watch: {
        $route() {
            const config = require(`../../assets/collections-config/${this.$route.params.collection}`)
            const { layout, title, subtitle, description } = config

            this.collection = this.$route.params.collection
            this.layout = layout
            this.title = title
            this.subtitle = subtitle
            this.description = description
        }
    },
    mounted() {
        window.addEventListener('language-localstorage-changed', (event) => {
            this.language = event.detail.language;
        });
    },
    methods: {
        getRowClass(index) {
            if (this.isMobile()) {
                return 'row-brighter'
            }
            if (index % 2 == 0) {
                return 'row-brighter'
            } else {
                return 'row-darker'
            }
        },
        getClass(item) {
            if (this.isMobile()) {
                return item.class + '-mobile'
            } else {
                return item.class
            }
        },
        getPhoto(filename) {
            return require(`../../assets/images/${this.$route.params.collection}/${filename}`);
        },
        openImage(filename, orientation) {
            this.filename = filename
            this.orientation = orientation
            this.showPhotoViewer = !this.showPhotoViewer
            document.documentElement.style.overflow = 'hidden'
            this.$mount();
        },
        closeImage() {
            this.showPhotoViewer = !this.showPhotoViewer
            document.documentElement.style.overflow = 'auto'
            this.$mount();
        }
    }
};
</script>

<style scoped>
.title {
    color: black;
    font-family: "Raleway-Light";
    font-size: 34pt;
}

.subtitle {
    color: black;
    font-family: "Raleway-Light";
    font-size: 14pt;
    font-style: italic;
    line-height: 1.6;
}

.description {
    color: black;
    font-family: "Raleway-Light";
    font-size: 14pt;
    line-height: 1.6;
}

.grid-1-mobile {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
    max-width: 80rem;
    margin: 2rem auto;
}

.grid-2-mobile {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 8vw;
    max-width: 80rem;
    margin: 2rem auto;
}

.grid-3-mobile {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 8vw;
    max-width: 80rem;
    margin: 2rem auto;
}

.grid-text-left-mobile {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
    margin: 2rem auto;
}

.grid-text-right-mobile {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
    margin: 2rem auto;
}

.grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    /* grid-gap: 1vw;
  margin: 2rem auto; */
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    /* grid-gap: 1vw;
  margin: 2rem auto; */
}

.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.grid-text-left {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.grid-text-right {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 1vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.type-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

.type-text-container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 7%;
}

.type-text {
    color: black;
    font-family: "Raleway-Light";
    font-size: 14pt;
    text-align: left;
    white-space: pre-line;
    line-height: 1.8;
    text-justify: inter-word;
}

.type-citation {
    color: black;
    font-family: "Raleway-ExtraLight";
    font-size: 13pt;
    font-style: italic;
    white-space: pre-line;
    line-height: 1.6;
    text-align: center;
}

.type-poem {
    color: black;
    font-family: "Raleway-ExtraLight";
    font-size: 12pt;
    font-style: italic;
    text-align: center;
    white-space: pre-line;
    line-height: 1.8;
}

.type-citation-author {
    display: flex;
    justify-content: center;
    color: black;
    font-family: "Raleway";
    font-size: 10pt;
    font-style: italic;
    text-align: left;
    white-space: pre-line;
}

.img-container {
    position: relative;
}

.img-container-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-family: "Raleway";
    font-size: 18pt;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity 0.5s linear;
    transition: visibility 0s, opacity 0.5s linear;
}

.img-container:hover {
    cursor: pointer;
}

.img-container:hover div {
    visibility: visible;
}

.row-darker {
    /* background-color: #E8E8E8; */
    background-color: #f5f5f5;
}

.row-brighter {
    background-color: #f5f5f5;
}
</style>