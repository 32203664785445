<template>
  <div
    v-if="!isMobile()"
    class="masthead"
    :style="$route.path == '/' ? 'height:15vh;' : ''"
  >
    <b-navbar
      class="colorWebsite navWithHero"
      toggleable="lg"
    >
      <img
        style="width:8%; padding-left: 2%;"
        class="m-2 ms-5"
        :src="isDarkTheme($route.path) ? require('../../assets/images/signature.png') : require('../../assets/images/signature_white.png')"
        :to="{ name: 'about' }"
      >
      <b-navbar-brand
        :to="{ name: 'home' }"
        :class="isDarkTheme($route.path) ? 'nav-name m-4' : 'nav-name-white m-4'"
      >Navega Amorim <br>
        <div class="nav-name-subtitle">{{ tr['topbar']['title'][language] }}</div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
        id="nav-collapse"
        is-nav
        class="justify-content-end m-2"
      >
        <b-navbar-nav class="ml-auto me-5">
          <b-nav-item :to="{ name: 'home' }">
            <div :class="getClass('home')">
              {{ tr['topbar']['home'][language] }}
            </div>
          </b-nav-item>
          <b-nav-item :to="{ name: 'collections' }">
            <div :class="getClass('collections')">
              {{ tr['topbar']['gallery'][language] }}
            </div>
          </b-nav-item>
          <b-nav-item :to="{ name: 'about' }">
            <div :class="getClass('about')">
              {{ tr['topbar']['about'][language] }}
            </div>
          </b-nav-item>
          <b-nav-item>
            <div class="d-flex flex-column justify-content-center m-3">
              <div
                :class="getLanguage() == 'PT' ? ('language-selected') : ('language')"
                @click="updateLanguage('PT')"
              >PT</div>
              <div
                :class="getLanguage() == 'EN' ? ('language-selected') : ('language')"
                @click="updateLanguage('EN')"
              >EN</div>
            </div>
          </b-nav-item> 
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <img
      class="hero"
      :src="getImage($route.path)"
    >
  </div>
  <div v-else>
    <b-navbar
      class="colorWebsite"
      toggleable="lg"
    >
      <b-navbar-brand
        :to="{ name: 'home' }"
        class="nav-name m-4"
      >
        Navega Amorim <br>
        <div class="nav-name-subtitle">{{ tr['topbar']['title'][language] }}</div>
      </b-navbar-brand>
      <!-- <b-navbar-toggle target="nav-collapse"> </b-navbar-toggle> -->

      <b-navbar-toggle
        target="nav-collapse"
        class="me-2"
      >
        <template #default="{ expanded }">
          <b-icon
            v-if="expanded"
            scale="2"
            icon="x"
          />
          <b-icon
            v-else
            scale="1.5"
            icon="list"
          />
        </template>
      </b-navbar-toggle>
      <b-collapse
        id="nav-collapse"
        is-nav
        class="mt-5"
      >
        <b-navbar-nav
          class="d-flex"
          style="height: 100vh;"
        >
          <b-nav-item :to="{ name: 'home' }">
            <div :class="getClass('home')">
              {{ tr['topbar']['home'][language] }}
            </div>
          </b-nav-item>
          <b-nav-item :to="{ name: 'collections' }">
            <div :class="getClass('collections')">
              {{ tr['topbar']['gallery'][language] }}
            </div>
          </b-nav-item>

          <b-nav-item :to="{ name: 'about' }">
            <div :class="getClass('about')">
              {{ tr['topbar']['about'][language] }}
            </div>
          </b-nav-item>
          <b-nav-item>
            <div class="d-flex flex-row justify-content-center m-2">
              <div
                :class="getLanguage() == 'PT' ? ('language-selected-mobile') : ('language-mobile')"
                @click="updateLanguage('PT')"
              >PT</div>
              <div
                v-if="isMobile()"
                style="width:5%"
              />
              <div
                :class="getLanguage() == 'EN' ? ('language-selected-mobile') : ('language-mobile')"
                @click="updateLanguage('EN')"
              >EN</div>
            </div>
          </b-nav-item> 
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
    name: "TopBar",
    data() {
        const tr = require(`../../assets/tr.json`)
        return {
            tr,
            language: this.$root.getLanguage()
        }
    },
    methods: {
        getClass(item) {
            const path = this.$route.path == '/' ? '/home' : this.$route.path;

            console.log("\n\n-->", this.$route.path);
            console.log("->", item);
            

            if (String(path).includes(item)) {
                const className = this.isDarkTheme(this.$route.path) ? 'nav-title-selected' : 'nav-title-selected-white';
                return `${className} ms-1 me-1 mt-4 mb-4`
            } else {
                const className = this.isDarkTheme(this.$route.path) ? 'nav-title' : 'nav-title-white';
                return `${className}  ms-1 me-1 mt-4 mb-4`
            }
        },
        updateLanguage(language) {
            this.$root.setLanguage(language)
            this.$mount();
            this.language = this.$root.getLanguage()
        },
        getImage(path) {
            if (String(path).includes('alvaomarao')) {
                return require("../../assets/images/alvaomarao/cover-hero.jpg")
            }
            if (String(path).includes('ethereal')) {
                return require("../../assets/images/ethereal/cover-hero.jpg")
            }
            if (String(path).includes('impressions')) {
                return require("../../assets/images/impressions/cover-hero.jpg")
            }
            if (String(path).includes('about')) {
                return require("../../assets/images/about-cover.jpg")
            }
            if (String(path).includes('home')) {
                //return require("../../assets/images/topbar-hero-black.png")
            }
            return require("../../assets/images/topbar-hero.png")
        },
        isDarkTheme(path) {
            if (String(path).includes('home')) {
                return true
            }
            return true
        }
    }
};
</script>

<style scoped>
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

@media (max-width: 760px) {
    .navbar .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    .navbar .navbar-collapse {
        text-align: center;
    }
}

.masthead {
    display: relative;
    background-color: whitesmoke;
}

.navWithHero {
    background: transparent;
    background-attachment: fixed;
    background-position: auto, center;
    top: 0;
    width: 100%;
    position: absolute;
}

.hero {
    height: auto;
    background-position: center center;
    width: 100%;
}

.nav-name {
    font-family: "Raleway-Regular";
    font-size: 15pt;
}

.nav-name-white {
    font-family: "Raleway-Regular";
    font-size: 15pt;
    color: white !important;
}

.nav-name-subtitle {
    font-family: "Raleway";
    font-size: 9pt;
}

.nav-name-subtitle-white {
    font-family: "Raleway";
    font-size: 9pt;
    color: white !important;
    ;
}

.nav-title {
    font-family: "Raleway-Regular";
    font-size: 12pt;
    color: black;
}

.nav-title-white {
    font-family: "Raleway-Regular";
    font-size: 12pt;
    color: white;
}

.nav-title-selected {
    font-family: "Raleway-Regular";
    font-size: 13pt;
    text-decoration: underline;
    text-underline-offset: 0.4em;
    text-decoration-thickness: 0.05rem;
    color: black
}

.language {
    font-family: "Raleway";
    font-size: 10pt;
    color: black;
}

.language-mobile {
    font-family: "Raleway";
    font-size: 12pt;
    color: black;
}

.language-selected {
    font-family: "Raleway";
    font-size: 10pt;
    font-weight: bolder;
    text-decoration: underline;
    color: black;
}

.language-selected-mobile {
    font-family: "Raleway";
    font-size: 12pt;
    font-weight: bolder;
    text-decoration: underline;
    color: black;
}

.language-dropdown{
    background-color: purple;
    width: 20px;
}
</style>