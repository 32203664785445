
export default {
    methods: {
        isMobile() {
            const BREAKPOINTS = {
                LABELS: ['xs', 'sm', 'md', 'lg', 'xl'],
                VALUES: [0, 768, 992, 1200, 1920, Infinity]
            };
            if (screen.width <= 760) {
                return true;
            } else {
                return false;
            }
        },
        setLanguage(language) {
            localStorage.setItem('language', language)
            window.dispatchEvent(new CustomEvent('language-localstorage-changed', {
                detail: {
                    language: localStorage.getItem('language')
                }
            }));
        },
        getLanguage() {
            return localStorage.getItem('language') || 'PT';
        }
    }
};