import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import Platform from './mixins/Platform'

import AOS from 'aos'
import 'aos/dist/aos.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/assets/css/main.css"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.mixin(Platform)

new Vue({
  mixins: [Platform],
  router,
  mounted() {
    AOS.init({
      once: true
    })
  },
  render: (h) => h(App)
}).$mount('#app')

