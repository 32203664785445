<template>
  <div class="home">
    <div class="section">
      <div class="home-image-container">
        <b-img
          class="home-image-cover"
          :src="require(`../assets/images/home-cover.jpg`)"
          fluid-grow
        />
        <div class="home-image-gradient">
          <div class="home-content">
            <div></div>
            <div class="d-flex flex-column align-items-center">
              <div class="custom-text-18">n a t u r e &nbsp; p h o t o g r a f y</div>
              <div class="custom-text-18">a &nbsp; s i l l e n t &nbsp; a p r o a c h</div>

            </div>
            <div class="d-flex flex-column justify-content-center align-items-center m-2">
              
              <div v-if="!isMobile()">
                <div :class="arrowVisible ? 'arrow': 'arrow-hidden'">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div v-else>
                <div :class="arrowVisible ? 'arrow-mobile m-5': 'arrow-mobile-hidden m-5'">
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isMobile() ? 'section-content-mobile p-5' : 'section-content p-5 mt-5'">
      <div class="section-content-item">
        <router-link :to="'/collections'">
          <b-img
            :class="isMobile() ? 'image-mobile m-3' : 'image-desktop m-4'"
            :src="require(`../assets/images/home-content-1.jpg`)"
          />
        </router-link>
        <div class="custom-text-18 mt-3"> {{ tr['home']['collections-title'][language] }}</div>
        <div class="custom-text-12 m-1">{{ tr['home']['collections-subtitle'][language] }}</div>
      </div>
      <div class="section-content-item">
        <router-link :to="'/about'">
          <b-img
            :class="isMobile() ? 'image-mobile m-3 mt-5' : 'image-desktop m-4'"
            :src="require(`../assets/images/home-content-2.jpg`)"
          />
        </router-link>
        <div class="custom-text-18 mt-3"> {{ tr['home']['about-title'][language] }}</div>
        <div class="custom-text-12 m-1">{{ tr['home']['about-subtitle'][language] }}</div>
      </div>
    </div>
    <div class="section-bottom">
      <div>
        <div class="quote mt-4 m-3">“That is not painting, but reality... That is not painting: it is stealing light and colour from Nature”</div>
        <hr
          style="width:10%; margin: 0 auto;"
          class="mt-2 mb-2"
        >
        <div class="quote-author">Blasco Ibáñez</div>
      </div>

    </div>
  </div>
</template>
<script>

export default {
    name: 'Home',
    data() {
        const tr = require(`../assets/tr.json`)
        return {
            tr,
            language: this.$root.getLanguage(),
            arrowVisible: true
        }
    },
    created () {
        window.addEventListener('scroll', this.scrollHandler);
    },
    unmounted () {
        window.removeEventListener('scroll', this.scrollHandler);
    },
    mounted() {
        window.addEventListener('language-localstorage-changed', (event) => {
            this.language = event.detail.language;
        });
    },
    methods: {
        scrollHandler() {
            this.arrowVisible =  Math.round(window.scrollY) > 100 ? false : true
        }
    }
}
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
}

.home-image-container {
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
}

.home-image-cover {
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 85vh;
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
}

.home-image-gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-image: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
    /* background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */

}

.home-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    align-self: center;
    padding: 2vh;
    height: 100%;
}

.section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    background-color: white;
}

.section-content {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    width: 100%;
}

.section-content-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    width: 100%;
}

.section-content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-content-item-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50vh;

}

.image-mobile {
    object-fit: cover;
    width: 40vh;
    height: 50vh;
}

.image-desktop {
    object-fit: cover;
    width: 60vh;
    height: 70vh;
}

.quote {
    color: black;
    font-family: "Raleway-ExtraLight";
    font-size: 14pt;
    font-style: italic;
    text-align: center;
    white-space: pre-line;
    line-height: 1.8;
}

.quote-author {
    color: black;
    font-family: "Raleway-ExtraLight";
    font-size: 9pt;
    font-style: italic;
    text-align: center;
    white-space: pre-line;
    line-height: 1.8;
}

/* arrow animated */
.arrow {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(360deg);
}

.arrow span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    animation: animate 2s infinite;
}

.arrow-hidden {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(360deg);
    visibility: hidden;
}

.arrow-hidden span {
    display: block;
    width: 1.5vw;
    height: 1.5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.arrow-mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(360deg);
}

.arrow-mobile span {
    display: block;
    width: 5vw;
    height: 5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    animation: animate 2s infinite;
}

.arrow-mobile-hidden {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(360deg);
    visibility: hidden;
}

.arrow-mobile-hidden span {
    display: block;
    width: 5vw;
    height: 5vw;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    animation: animate 2s infinite;
}


.arrow-mobile span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow-mobile span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

/* arrow animated */

</style>
