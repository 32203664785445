<template>
  <div
    id="app"
    class="colorWebsite"
  >
    <TopBar v-if="showNavBar" />
    <router-view style="min-height: 80vh" />
    <BottomBar class="colorWebsite" />
  </div>
</template>

<script>
import TopBar from './components/navigation/TopBar'
import BottomBar from './components/navigation/BottomBar'

export default {
  name: 'App',
  components: {
    TopBar,
    BottomBar
  },
  data() {
    return {
      showNavBar: this.$route.name != "intro"
    }
  },
  watch: {
    $route() {
      this.showNavBar = this.$route.name != "intro"
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Raleway";
  font-weight: 100;
  font-style: normal;
  font-display: auto;
  src: local("Raleway"),
    url(../public/fonts/Raleway/static/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Regular";
  font-weight: 100;
  font-style: normal;
  font-display: auto;
  src: local("Raleway"),
    url(../public/fonts/Raleway/static/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-Light";
  font-weight: 100;
  font-style: normal;
  font-display: auto;
  src: local("Raleway"),
    url(../public/fonts/Raleway/static/Raleway-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway-ExtraLight";
  font-weight: 100;
  font-style: normal;
  font-display: auto;
  src: local("Raleway-ExtraLight"),
    url(../public/fonts/Raleway/static/Raleway-ExtraLight.ttf) format("truetype");
}

#app {
  position: relative;
  min-height: 100vh;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  /* Footer height */
}
</style>
